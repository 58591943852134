<template>
	<div class="news">
		<pagehead :headText="headText"></pagehead>
		<div class="learn-box">
			<!-- <ul class="learn-list">
				<li
					v-for="(item, i) in tabList"
					:key="i"
					:class="tabLen == i ? 'learn-active' : ''"
					@click="learnClick(item, i)"
				>
					{{ item.columnName }}
				</li>
			</ul> -->
		</div>
		<div class="news-list">
			<ul class="news-list-box">
				<li v-for="(item, i) in newlist" :key="i" @click="details(item, i)">
					<div>
						<p>{{ item.publishTime.split(' ')[0].split('-')[2] }}</p>
						<p>{{ item.publishTime.split(' ')[0].split('-')[0] + '-' + item.publishTime.split(' ')[0].split('-')[1] }}</p>
					</div>
					<div>
						<p>{{ item.noticeTitle }}</p>
						<p class="el-icon-right"></p>
					</div>
				</li>
			</ul>
			<div class="pagin-box">
				<el-pagination
					layout="prev, pager, next"
					:page-size="20"
					:total="4"
					@prev-click="prevclick"
					@next-click="nextclick"
					@current-change="currentchange"
				></el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import {
	listColumn,
	listInfoByColumnName,
	pageListInfoByColumnName,
	pageListInfoByColumnId,
	listInfoByColumnId
} from '@/api/api.js'
import pagehead from '@/components/Pagehead'
export default {
	data() {
		return {
			parentabs: this.$store.state.tabs, //父级路由信息
			tabList: [],
			newlist: [],
			tabLen: 0,
			headText: '',
			columnId: '',
			total: 0,
			pageSize: 20,
			pageNum: 1,
			columnName: ''
		}
	},
	props: {},
	components: { pagehead },
	created() {},
	computed: {},
	watch: {
		'$store.state.tabs': {
			handler(newVal) {
				console.log('newVal', newVal)
				this.columnId = newVal.columnId
				this.Getcolumnitem()
				// this.getColumnList()
				// let params = {
				// 	parentId: newVal.columnId
				// }
				// listColumn(params)
				// 	.then(res => {
				// 		this.tabList = res
				// 		console.log('------>>>>this.$route.query.tabName', this.$route.query.tabName)
				// 		if (this.$route.query.tabName) {
				// 			let tabName = this.$route.query.tabName
				// 			for (let i = 0; i < res.length; i++) {
				// 				console.log('-------+++++', res[i].columnName)
				// 				if (tabName == res[i].columnName) {
				// 					this.headText = res[i].columnName
				// 					this.columnId = res[i].columnId

				// 					this.Getcolumnitem()
				// 					this.tabLen = i
				// 				}
				// 			}
				// 		} else {
				// 			this.headText = res[0].columnName
				// 			this.columnId = res[0].columnId

				// 			this.Getcolumnitem()
				// 		}
				// 	})
				// 	.catch(error => {
				// 		this.$message.error('获取数据失败！')
				// 	})
			}
		}
	},
	mounted() {
		console.log(this.$store.state.tabs, '-------------------')
		this.columnId = this.$store.state.tabs.columnId
		this.Getcolumnitem()
		// let params = {
		// 	parentId: this.parentabs.columnId
		// }
		// listColumn(params)
		// 	.then(res => {
		// 		this.tabList = res
		// 		if (this.$route.query.tabName) {
		// 			let tabName = this.$route.query.tabName
		// 			for (let i = 0; i < res.length; i++) {
		// 				if (tabName == res[i].columnName) {
		// 					this.headText = res[i].columnName
		// 					this.columnId = res[i].columnId
		// 					this.Getcolumnitem()
		// 					this.tabLen = i
		// 				}
		// 			}
		// 		} else {
		// 			this.headText = res[0].columnName
		// 			this.columnId = res[0].columnId
		// 			this.Getcolumnitem()
		// 		}
		// 	})
		// 	.catch(error => {
		// 		this.$message.error('获取数据失败！')
		// 	})
	},
	methods: {
		prevclick(num) {
			this.pageNum = num
			//上一页
			this.Getcolumnitem()
		},
		currentchange(num) {
			this.pageNum = num
			this.Getcolumnitem()
		},
		nextclick(num) {
			this.pageNum = num
			//下一页
			this.Getcolumnitem()
		},
		learnClick(item, i) {
			this.tabLen = i
			this.headText = item.columnName
			this.columnId = item.columnId

			this.Getcolumnitem(item.columnId)
			//this.$store.commit('setTabs', item)
		},
		details(item, i) {
			this.$router.replace({
				path: '/newdetaile',
				query: {
					id: item.noticeId
				}
			})
		},
		Getcolumnitem() {
			let params = {
				pageSize: this.pageSize,
				pageNum: this.pageNum,
				columnId: this.columnId
			}
			pageListInfoByColumnId(params)
				.then(res => {
					this.newlist = res.rows
					this.total = res.total
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},
		getColumnList() {
			let params = {
				pageSize: this.pageSize,
				pageNum: this.pageNum,
				columnId: this.columnId
			}
			listInfoByColumnId(params)
				.then(res => {
					console.log('-------_>>>>>res.code', res)
					this.newlist = res
					this.total = res.total
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		}
	}
}
</script>
<style scoped lang="scss">
.news {
	width: 100%;
	padding-bottom: 50px;
	.learn-box {
		width: 100%;
		padding-top: 50px;
		.learn-list {
			margin: 0 auto;
			width: 1330px;
			height: 80px;
			border: 1px solid #d4e6e6;
			display: flex;
			flex-direction: row;
			li {
				flex: 3;
				text-align: center;
				line-height: 80px;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #666666;
				cursor: pointer;
			}
			.learn-active {
				background: #d4e6e6;
				color: #237e80;
			}
		}
	}
	.news-list {
		width: 100%;
		padding-top: 52px;
		.news-list-box {
			width: 1330px;
			margin: 0 auto;
			li {
				width: 100%;
				overflow: hidden;
				div:nth-child(1) {
					background: #f1f1f1;
					width: 120px;
					height: 120px;
					margin-top: 20px;
					float: left;
					p:nth-child(1) {
						margin-top: 23px;
						font-size: 48px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #333333;
						line-height: 48px;
					}
					p:nth-child(2) {
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						line-height: 18px;
						padding-top: 10px;
					}
				}
				div:nth-child(2) {
					width: 1098px;
					float: right;
					text-align: left;
					margin-left: 52px;
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid #ccc;
					padding: 65px 0px 45px 60px;
					p:nth-child(1) {
						font-size: 22px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						line-height: 48px;
						padding-right: 190px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					p:nth-child(2) {
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 30px;
					}
				}
			}
			li:hover {
				cursor: pointer;
				background: #237e80;
			}
			li:hover div:nth-child(1) {
				background: none;
			}
			li:hover div:nth-child(2) {
				border: none;
			}
			li:hover div:nth-child(1) p {
				color: #fff;
			}
			li:hover div:nth-child(2) p {
				color: #fff;
			}
			li:hover div:nth-child(2) p:nth-child(2) {
				padding-right: 20px;
			}
		}
		.pagin-box {
			padding-top: 84px;
		}
	}
}
::v-deep .btn-prev {
	background: #f1f1f1 !important;
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
	padding: 0px !important;
}
::v-deep .btn-prev:hover {
	background: #237e80 !important;
	color: #fff;
}
::v-deep .btn-next {
	background: #f1f1f1 !important;
	border-radius: 50% !important;
	width: 40px !important;
	height: 40px !important;
	padding: 0px !important;
}
::v-deep .btn-next:hover {
	background: #237e80 !important;
	color: #fff;
}
::v-deep .el-pager {
	margin-top: 8px;
	margin-left: 0px;
	margin-right: 10px;
}
</style>
